/**
 * 下單機
 *
 * DefaultCond 預設下單商品 S:證券F:期貨O:選擇權 OF:海期 OO:海選
 *
 * DisplayCond 顯示商品 S:證券 F:期貨 SF:證券+期貨 stockid 股票代號 FuntureID 期貨代號 OverseasFuntureID 海期商品代號
 */
export const capitalOrderStock = 'https://tradeweb..com.tw/capitalh5/Login.aspx?source=zz&DefaultCond=S&DisplayCond=S&stockID=';
export const capitalOrderFutures = 'https://tradeweb.capital.com.tw/capitalh5/Login.aspx?source=zz&DefaultCond=F&DisplayCond=F&FuntureID=';
export const capitalOrderOSFutures = 'https://tradeweb.capital.com.tw/capitalh5/Login.aspx?source=zz&DefaultCond=OF&DisplayCond=F';
// IBF Financial Holdings 國票金控
export const ibfOrderFutures = 'https://itrade.ibfs.com.tw/APP/FuturesLite/Forder.aspx?no=TXFC4&volumn=&trade=&c=';
